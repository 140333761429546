import isNil from 'ramda/src/isNil'
import getRecordSelector from 'root/src/client/logic/api/selectors/getRecordSelector'
import moment from 'moment-mini'
import { projectDeliveredKey, projectPendingKey } from 'root/src/shared/constants/keys'

export const getProjectTimeLeft = (project) => {
	const approved = project?.approved
	if (!approved) {
		return 0
	}
	const delivered = project.status === projectDeliveredKey
	const pending = project.status === projectPendingKey

	if ((isNil(approved) || delivered) && !pending) {
		return 0
	}

	const expirationDate = project?.expirationDate
	return moment(expirationDate).diff(moment().format(), 'seconds')
}

export default (state, props) => {
	const project = getRecordSelector(state, props)
	return getProjectTimeLeft(project)
}
