// eslint-disable-next-line no-restricted-imports
import urlParser from '@daredrop/js-video-url-parser';
import { Platform } from 'root/src/shared/@types/platforms';
const twitchCollectionURL = 'twitch.tv/collections/';
const collectionUrlParser = (url) => {
    const urlSplit = url.split('/');
    const id = urlSplit[urlSplit.length - 1];
    return {
        provider: Platform.Twitch,
        mediaType: 'collection',
        id,
    };
};
const twitterUrl = 'twitter.com/';
const xUrl = 'x.com/';
function isTwitterUrl(url) {
    return url.includes(twitterUrl) || url.includes(xUrl);
}
const twitterUrlParser = (url) => ({
    provider: 'twitter',
    mediaType: 'text/html',
    id: url.replace('x.com', 'twitter.com'),
});
const youtubeClipUrl = 'youtube.com/clip/';
const youtubeShortsUrl = 'youtube.com/shorts/';
const youtubeClipParser = (url) => {
    const urlSplit = url.split('/');
    const id = urlSplit[urlSplit.length - 1];
    return {
        provider: Platform.YouTube,
        mediaType: 'clip',
        id,
    };
};
const youtubeShortsParser = (url) => {
    const urlSplit = new URL(url).pathname.split('/');
    const id = urlSplit[urlSplit.length - 1];
    return {
        provider: Platform.YouTube,
        mediaType: 'shorts',
        id,
    };
};
export default (videoUrl) => {
    if (videoUrl && videoUrl.includes(twitchCollectionURL)) {
        return collectionUrlParser(videoUrl);
    }
    if (videoUrl && isTwitterUrl(videoUrl)) {
        return twitterUrlParser(videoUrl);
    }
    if (videoUrl && videoUrl.includes(youtubeClipUrl)) {
        return youtubeClipParser(videoUrl);
    }
    if (videoUrl && videoUrl.includes(youtubeShortsUrl)) {
        return youtubeShortsParser(videoUrl);
    }
    return urlParser.parse(videoUrl);
};
