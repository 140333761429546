import getRecordSelector from 'root/src/client/logic/api/selectors/getRecordSelector';
import { GET_PROJECT } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { getResponseLenses } from 'root/src/shared/descriptions/getEndpointDesc';
import split from 'ramda/src/split';
import test from 'ramda/src/test';
import memoizeWith from 'ramda/src/memoizeWith';
import twitterLogo from 'root/src/client/public/twitter-logo-banner.png';
// eslint-disable-next-line no-restricted-imports
import urlParser from '@daredrop/js-video-url-parser';
import urlParserMod from 'root/src/shared/util/urlParser';
const responseLenses = getResponseLenses(GET_PROJECT);
const { viewDeliveries } = responseLenses;
export const getProjectDelivery = (deliveries) => {
    const delivery = deliveries === null || deliveries === void 0 ? void 0 : deliveries[0];
    if (!delivery) {
        return null;
    }
    const { videoURL, timeStamp: timestamp, thumbnail, embeddedContent, notesForReviewer, params, } = delivery || {};
    // urlParser doesn't support collection links
    const parsedUrl = urlParserMod(videoURL);
    if (!parsedUrl) {
        return null;
    }
    // at the moment selector returns simply url of first delivery in deliveries array
    const playerUrl = urlParser.create({
        videoInfo: parsedUrl,
        format: parsedUrl.provider === 'embed',
    });
    const [hh, mm, ss] = split(':', timestamp);
    const startTime = `t=${hh}h${mm}m${ss}s`;
    const getThumbnail = () => {
        if (parsedUrl.provider === 'twitter') {
            return twitterLogo.src;
        }
        return thumbnail;
    };
    const deliveryDetails = {
        id: parsedUrl.id,
        timestamp,
        thumbnail: getThumbnail(),
        timestampQuery: startTime,
        deliveryURL: playerUrl ? `${playerUrl}${test(/.*\?.*/, playerUrl) ? '&' : '?'}${startTime}` : undefined,
        videoURL,
        mediaType: parsedUrl.mediaType,
        embeddedContent,
        notesForReviewer,
        platform: parsedUrl.provider,
        params,
    };
    return deliveryDetails;
};
export default memoizeWith((state, props) => JSON.stringify(viewDeliveries(getRecordSelector(state, props))), (state, props) => getProjectDelivery(viewDeliveries(getRecordSelector(state, props))));
